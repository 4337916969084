import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row mt-3" }
const _hoisted_2 = { class: "grid grid-cols-2 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailStatusCardDashboardClient = _resolveComponent("DetailStatusCardDashboardClient")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock(_component_CardDashboardClient, { title: "Nilai COD" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DetailStatusCardDashboardClient, {
          class: "w-60 pr-3",
          title: _ctx.totalCodValue.title,
          tooltipTitle: _ctx.totalCodValue.tooltip,
          loading: _ctx.totalCodValue.loading,
          value: _ctx.totalCodValue.amount,
          subValue: _ctx.totalCodValue.resi,
          isValueCurrency: ""
        }, null, 8, ["title", "tooltipTitle", "loading", "value", "subValue"]),
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.codValuePerStatus, (codValue) => {
            return (_openBlock(), _createBlock(_component_DetailStatusCardDashboardClient, {
              class: "px-3 border-l",
              key: codValue,
              title: codValue.title,
              tooltipTitle: codValue.tooltip,
              loading: codValue.loading,
              value: codValue.amount,
              subValue: codValue.resi,
              color: codValue.color,
              ableToClick: codValue.ableToClick,
              isValueCurrency: "",
              onClick: ($event: any) => (_ctx.onClickDetail(`cod-${codValue.type}`))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ProgressBar, {
                  value: _ctx.valueProgressBar(codValue.amount, _ctx.totalCodValue.amount),
                  class: "w-full mr-2",
                  startValue: 0,
                  endValue: _ctx.totalCodValue.amount,
                  isFormatMoney: "",
                  customColor: codValue.color
                }, null, 8, ["value", "endValue", "customColor"])
              ]),
              _: 2
            }, 1032, ["title", "tooltipTitle", "loading", "value", "subValue", "color", "ableToClick", "onClick"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}