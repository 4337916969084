import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex flex-wrap items-center gap-2" }
const _hoisted_4 = {
  key: 0,
  class: "mt-2 text-gray-lp-300 text-12px font-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bullet_point = _resolveComponent("bullet-point")!
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createBlock("div", {
    class: [_ctx.ableToClick ? 'cursor-pointer' : '', "text-gray-lp-800 font-medium text-12px"],
    onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onHover && _ctx.onHover(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLeaveHover && _ctx.onLeaveHover(...args))),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createVNode("div", {
      style: _ctx.styleCard,
      class: _ctx.classContainer
    }, [
      _createVNode("div", {
        class: 
          `flex flex-nowrap whitespace-nowrap items-center  text-14px text-black-lp-300 ${_ctx.classTitle}`
        
      }, [
        (_ctx.color && _ctx.point)
          ? (_openBlock(), _createBlock(_component_bullet_point, {
              key: 0,
              style: `color:${_ctx.color}`,
              class: "w-2 mr-3"
            }, null, 8, ["style"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.title) + " ", 1),
        (_ctx.tooltipTitle)
          ? (_openBlock(), _createBlock(_component_TooltipV2, {
              key: 1,
              widthIcon: "w-4",
              description: _ctx.tooltipTitle
            }, null, 8, ["description"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.loading)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode(_component_Skeleton, {
              class: "mt-2",
              height: "1rem"
            }),
            _createVNode(_component_Skeleton, {
              class: "mt-2",
              height: "1rem"
            })
          ]))
        : (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("div", {
              class: ["my-1 text-20px whitespace-nowrap font-semibold", _ctx.colorValue],
              style: _ctx.styleText
            }, [
              _createVNode("div", _hoisted_3, [
                (_ctx.isValueCurrency)
                  ? (_openBlock(), _createBlock("span", {
                      key: 0,
                      class: `font-medium align-top ${_ctx.classValue}`
                    }, "Rp", 2))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.formattedValue) + " ", 1),
                _renderSlot(_ctx.$slots, "suffix")
              ])
            ], 6),
            _renderSlot(_ctx.$slots, "default"),
            (_ctx.subValue)
              ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.formattedSubValue) + " Resi ", 1))
              : _createCommentVNode("", true)
          ]))
    ], 6)
  ], 34))
}