
import { Vue, Options, prop } from "vue-class-component";
class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  tooltipTitle = prop<string>({
    default: "",
    type: String
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({})
export default class CardDashboardClient extends Vue.with(Props) {}
