
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import DonutChart from "@/app/ui/components/donut-chart/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";

@Options({
  components: {
    BulletPoint,
    CardDashboardClient,
    DonutChart
  }
})
export default class CodVsNonCodCard extends Vue {
  mounted() {
    this.fetchDataCodNonCod();
  }
  async fetchDataCodNonCod() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/cod/ratio",
        sttCategory: "",
        cache: true
      }
    );
    this.dataCodNonCod.cod = response.totalSttCod;
    this.dataCodNonCod.nonCod = response.totalSttNonCod;
    this.dataCodNonCod.total = response.totalStt;
    this.isLoading = false;
  }

  isLoading = true;
  dataCodNonCod = {
    total: 0,
    cod: 0,
    nonCod: 0
  };
  get dataCodVsNonCod() {
    return {
      cod: {
        percentage:
          this.dataCodNonCod.total === 0
            ? 0
            : (
                (this.dataCodNonCod.cod / this.dataCodNonCod.total) *
                100
              ).toLocaleString("id", { maximumFractionDigits: 2 }),
        resi: this.dataCodNonCod.cod
      },
      nonCod: {
        percentage:
          this.dataCodNonCod.total === 0
            ? 0
            : (
                (this.dataCodNonCod.nonCod / this.dataCodNonCod.total) *
                100
              ).toLocaleString("id", { maximumFractionDigits: 2 }),
        resi: this.dataCodNonCod.nonCod
      }
    };
  }
  get chartData() {
    return [this.dataCodVsNonCod.cod.resi, this.dataCodVsNonCod.nonCod.resi];
  }
  chartColor = ["#36B37E", "#C0EBCF"];
}
