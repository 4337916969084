
import { Vue, prop, Options } from "vue-class-component";
import ProgressBar from "primevue/progressbar";
import { formatPriceRP } from "@/app/infrastructures/misc/Utils";

class Props {
  value = prop<number>({
    // actual progress
    type: Number,
    default: 0
  });
  realValue = prop<number>({
    type: Number,
    default: 0
  });
  startValue = prop<number>({
    type: Number,
    default: 0
  });
  endValue = prop<number>({
    type: Number,
    default: 0
  });
  isFormatMoney = prop<boolean>({
    type: Boolean,
    default: false
  });
  customColor = prop<string>({
    type: String,
    default: "#F05C72"
  });
  multipleBar = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  components: {
    ProgressBar
  }
})
export default class ProgressBars extends Vue.with(Props) {
  formatMoney(val: number) {
    return formatPriceRP(val);
  }
}
