
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import ProgressBar from "@/app/ui/components/progress-bar/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DataCorporateDashboard } from "@/domain/entities/Dashboard";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { TRACK_DELIVERY } from "@/app/infrastructures/misc/RolePermission";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";

@Options({
  components: {
    DetailStatusCardDashboardClient,
    CardDashboardClient,
    ProgressBar
  }
})
export default class CodValueCard extends Vue {
  async mounted() {
    await this.fetchDataTotalCodValue();
    await this.fetchDataCodValue();
  }
  async fetchDataTotalCodValue() {
    if (this.deliveryType === "non_cod") {
      this.totalCodValue.amount = 0;
      this.totalCodValue.resi = 0;
      return;
    }
    this.totalCodValue.loading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/cod/status",
        sttCategory: "all",
        deliveryType: "cod",
        cache: true
      }
    );
    this.totalCodValue.amount = response.totalCodAmount;
    this.totalCodValue.resi = response.totalStt;
    this.totalCodValue.loading = false;
  }
  async fetchDataCodValue() {
    if (this.deliveryType === "non_cod") {
      this.codValuePerStatus[0].amount = 0;
      this.codValuePerStatus[0].resi = 0;
      this.codValuePerStatus[1].amount = 0;
      this.codValuePerStatus[1].resi = 0;
      return;
    }
    let delay = 0;
    const delayIncrement = 250;
    const requestCodValue = this.codValuePerStatus.map((delivery: any) => {
      delivery.loading = true;
      delay += delayIncrement;
      return new Promise(resolve => {
        setTimeout(resolve, delay);
      }).then(() => {
        DashboardController.getCorporateDashboardDeliveryData({
          path: "/cod/status",
          sttCategory: delivery.type,
          cache: true
        }).then((resp: DataCorporateDashboard) => {
          delivery.loading = false;
          setTimeout(() => {
            delivery.amount = resp.totalCodAmount;
            delivery.resi = resp.totalStt;
          }, 100);
        });
      });
    });
    await Promise.all(requestCodValue);
  }

  // nilai cod
  totalCodValue = {
    title: "Estimasi Total Nilai COD",
    tooltip:
      "<ol style='list-style-type: decimal;'>" +
      "<li>Estimasi Total Nilai COD yang akan diterima</li>" +
      "<li>Total STT COD yang berhasil terbooking</li>" +
      "</ol>",
    type: "TOTAL_COD_AMOUNT",
    amount: 0,
    resi: 0,
    loading: false
  };
  codValuePerStatus: Array<any> = [
    {
      title: "Dalam Proses",
      tooltip:
        "<ol style='list-style-type: decimal;'>" +
        "<li>Estimasi Total Nilai COD yang dalam proses pengiriman</li>" +
        "<li>Total STT COD yang dalam proses pengiriman</li>" +
        "</ol>",
      type: "IN_PROGRESS",
      amount: 0,
      resi: 0,
      loading: false,
      color: "#FAB713",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Telah Diterima",
      tooltip:
        "<ol style='list-style-type: decimal;'>" +
        "<li>Estimasi Total Nilai COD yang akan diterima</li>" +
        "<li>Total STT COD yang berhasil terbooking</li>" +
        "</ol>",
      type: "SUCCESS",
      amount: 0,
      resi: 0,
      loading: false,
      color: "#36B37E",
      ableToClick: this.ableToViewDetail
    }
  ];
  valueProgressBar(val: number, maxValue: number) {
    return (val / maxValue) * 100;
  }

  get deliveryType() {
    return DashboardController.filterCorporateDashboard.deliveryType.value;
  }

  get ableToViewDetail() {
    if (IS_SENDER_ACCOUNT) return true;
    return checkRolePermission(TRACK_DELIVERY.ENABLE);
  }
  onClickDetail(dashboardInfo: string) {
    if (!this.ableToViewDetail) return;
    DashboardController.openNewPageFromDashboardCorporate(dashboardInfo);
  }
}
