import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border border-gray-lp-1300 rounded-lg p-4 overflow-hidden" }
const _hoisted_2 = { class: "text-gray-lp-300 font-semibold text-16px" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "flex flex-nowrap items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.tooltipTitle)
            ? (_openBlock(), _createBlock(_component_TooltipV2, {
                key: 0,
                description: _ctx.tooltipTitle
              }, null, 8, ["description"]))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "top-right")
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Skeleton, {
            class: "mt-2",
            height: "20%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mt-2",
            height: "20%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mt-2",
            height: "20%"
          })
        ], 64))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}