import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-x-4 flex-row flex-nowrap mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_cod_vs_non_cod_card = _resolveComponent("cod-vs-non-cod-card")!
  const _component_cod_value_card = _resolveComponent("cod-value-card")!
  const _component_cod_cancel_value_card = _resolveComponent("cod-cancel-value-card")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, { label: "COD" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_cod_vs_non_cod_card, { class: "w-4/12" }),
      _createVNode(_component_cod_value_card, { class: "w-6/12" }),
      _createVNode(_component_cod_cancel_value_card, { class: "w-2/12" })
    ])
  ]))
}