
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { TRACK_DELIVERY } from "@/app/infrastructures/misc/RolePermission";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";

@Options({
  components: {
    DetailStatusCardDashboardClient,
    CardDashboardClient
  }
})
export default class CodCancelValueCard extends Vue {
  mounted() {
    this.fetchDataCancelCodValue();
  }
  async fetchDataCancelCodValue() {
    if (this.deliveryType === "non_cod") {
      this.failedCodValue.amount = 0;
      this.failedCodValue.resi = 0;
      return;
    }
    this.failedCodValue.loading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/cod/status",
        sttCategory: "CANCEL",
        cache: true
      }
    );
    this.failedCodValue.amount = response.totalCodAmount;
    this.failedCodValue.resi = response.totalStt;
    this.failedCodValue.loading = false;
  }
  failedCodValue = {
    title: "Nilai COD",
    tooltip:
      "<ol style='list-style-type: decimal;'>" +
      "<li>Estimasi Total Nilai COD yang gagal terkirim</li>" +
      "<li>Total STT COD yang gagal dikirim</li>" +
      "</ol>",
    type: "CANCEL",
    amount: 0,
    resi: 0,
    loading: false
  };
  get deliveryType() {
    return DashboardController.filterCorporateDashboard.deliveryType.value;
  }

  get ableToViewDetail() {
    if (IS_SENDER_ACCOUNT) return true;
    return checkRolePermission(TRACK_DELIVERY.ENABLE);
  }
  onClickDetail(dashboardInfo: string) {
    if (!this.ableToViewDetail) return;
    DashboardController.openNewPageFromDashboardCorporate(dashboardInfo);
  }
}
