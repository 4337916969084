
import { Vue, Options } from "vue-class-component";
import CodVsNonCodCard from "@/app/ui/views/dashboard/component/client/section-cod/cod-vs-non-cod-card.vue";
import CodValueCard from "@/app/ui/views/dashboard/component/client/section-cod/cod-value-card.vue";
import CodCancelValueCard from "@/app/ui/views/dashboard/component/client/section-cod/cod-cancel-value-card.vue";

@Options({
  components: {
    CodCancelValueCard,
    CodValueCard,
    CodVsNonCodCard
  }
})
export default class SectionCodDashboard extends Vue {}
