import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-10px text-white text-center"
}
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-between"
}
const _hoisted_3 = { class: "text-gray-lp-800 text-12px" }
const _hoisted_4 = { class: "text-gray-lp-800 text-12px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: `${!_ctx.multipleBar ? 'h-6' : 'h-2'}`
  }, [
    _createVNode("div", {
      role: "progressbar",
      class: "p-progressbar p-component p-progressbar-determinate",
      style: `height: ${!_ctx.multipleBar ? '8px' : '14px'}`,
      "aria-valuemin": 0,
      "aria-valuenow": _ctx.value,
      "aria-valuemax": 100
    }, [
      _createVNode("div", {
        class: "p-progressbar-value p-progressbar-value-animate",
        style: `width: ${_ctx.value}%; display: block; background: ${_ctx.customColor}`
      }, [
        (_ctx.multipleBar)
          ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.realValue), 1))
          : _createCommentVNode("", true)
      ], 4)
    ], 12, ["aria-valuenow"]),
    (!_ctx.multipleBar)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("span", _hoisted_3, _toDisplayString(_ctx.isFormatMoney ? _ctx.formatMoney(_ctx.startValue) : _ctx.startValue), 1),
          _createVNode("span", _hoisted_4, _toDisplayString(_ctx.isFormatMoney ? _ctx.formatMoney(_ctx.endValue) : _ctx.endValue), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}