
import { Vue, Options, prop } from "vue-class-component";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  classTitle = prop<string>({
    default: "font-semibold",
    type: String
  });
  value = prop<number>({
    default: 0,
    type: Number
  });
  subValue = prop<number>({
    default: 0,
    type: Number
  });
  tooltipTitle = prop<string>({
    default: "",
    type: String
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
  colorValue = prop<string>({
    default: "text-gray-lp-300",
    type: String
  });
  isValueCurrency = prop<boolean>({
    default: false,
    type: Boolean
  });
  color = prop<string>({
    default: "",
    type: String
  });
  classContainer = prop<string>({
    default: "py-4",
    type: String
  });
  classValue = prop<string>({
    default: "text-12px",
    type: String
  });
  ableToClick = prop<boolean>({
    default: false,
    type: Boolean
  });
  point = prop<boolean>({
    default: true,
    type: Boolean
  });
}
@Options({
  emits: ["click"],
  components: { BulletPoint }
})
export default class DetailStatusCardDashboardClient extends Vue.with(Props) {
  get formattedValue() {
    return formatPriceWithoutCurrency(this.value);
  }
  get formattedSubValue() {
    return formatPriceWithoutCurrency(this.subValue);
  }

  styleText: any = "";
  styleCard: any = "";
  onHover() {
    if (!this.ableToClick) return;
    this.styleText = {
      color: this.color
    };
    this.styleCard = {
      backgroundColor: "#F5F5F5",
      paddingLeft: "1rem",
      paddingRight: "1rem"
    };
  }
  onLeaveHover() {
    this.styleText = "";
    this.styleCard = "";
  }
}
